import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import HomePage from "./view/pages/home";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivacyPolicyPage from "./view/pages/privacyPolicy";
import NotFoundPage from "./view/pages/404";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route status={404} component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
