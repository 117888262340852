import logo from "./logo.png";
import "./Header.css";
import React from "react";
import { Link } from "react-router-dom";

class TemplateHeader extends React.Component {
  constructor() {
    super();

    // State definition
    this.state = {
      burgerOpened: false,
    };

    // Function binding
    this.toggleNavMenu = this.toggleNavMenu.bind(this);
  }

  toggleNavMenu() {
    this.setState((state, _) => ({
      burgerOpened: !state.burgerOpened,
    }));
  }

  render() {
    return (
      <header id="TemplateHeader">
        <div className="container">
          <nav
            id="TemplateHeader-navbar"
            className="navbar is-transparent"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="navbar-brand">
              <Link className="navbar-item" to="/">
                <img src={logo} alt="logo" />
              </Link>

              <button
                className={
                  this.state.burgerOpened
                    ? "navbar-burger is-active"
                    : "navbar-burger"
                }
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                onClick={this.toggleNavMenu}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </button>
            </div>

            <div
              id="navbarBasicExample"
              className={
                this.state.burgerOpened
                  ? "navbar-menu is-active"
                  : "navbar-menu"
              }
            >
              <div className="navbar-end">
                <Link className="navbar-item" to="/">
                  Home
                </Link>

                <Link className="navbar-item" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default TemplateHeader;
