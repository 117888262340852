import "./Footer.css";
import { Link } from "react-router-dom";

function TemplateFooter() {
  return (
    <footer className="footer" id="TemplateFooter">
      <div className="container">
        <div className="columns">
          <div className="column is-half">&copy; 2021 Eco POS</div>
          <div className="column">
            <strong className="footer-title">Eco POS</strong>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="column">
            <strong className="footer-title">Download</strong>
            <ul>
              <li>
                <a href="https://play.google.com/store/apps/details?id=com.tujuhlab.ecopos">
                  Android
                </a>
              </li>
            </ul>
          </div>
          <div className="column">
            <strong className="footer-title">Contact</strong>
            <ul>
              <li>
                <a href="mailto:&#116;&#117;&#106;&#117;&#104;&#108;&#97;&#98;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;">
                  Email
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default TemplateFooter;
