import "./404.css";
import TemplateHeader from "../templates/Header";
import TemplateFooter from "../templates/Footer";
import React from "react";
import { Helmet } from "react-helmet";

class NotFoundPage extends React.Component {
  componentDidMount() {
    document.title = "Eco POS - Page Not Found";
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta property="og:title" content="Eco POS - Page Not Found" />
          <title>Eco POS - Page Not Found</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <TemplateHeader />
        <div id="NotFoundPage" className="section">
          <div className="container has-text-centered">
            <span className="icon is-large mt-6">
              <i className="fas fa-exclamation-triangle fa-7x"></i>
            </span>
            <h1 className="title mb-6 mt-6">Page Not Found</h1>
            <p className="subtitle">
              The link may be broken, or the page may have been removed. Check
              to see if the link you're trying to open is correct.
            </p>
          </div>
        </div>
        <TemplateFooter />
      </div>
    );
  }
}

export default NotFoundPage;
