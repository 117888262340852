import "./home.css";
import phone from "./phone.png";
import React from "react";
import TemplateHeader from "../templates/Header";
import TemplateFooter from "../templates/Footer";
import { Helmet } from "react-helmet";

const feature1TextColor = "#2F7529";
const feature1Text = {
  color: feature1TextColor,
};
const feature1 = {
  backgroundColor: "#76CD6D",
  color: feature1TextColor,
};

const feature2TextColor = "#753600";
const feature2Text = {
  color: feature2TextColor,
};
const feature2 = {
  backgroundColor: "#FF9726",
  color: feature2TextColor,
};

const feature3TextColor = "#832626";
const feature3Text = {
  color: feature3TextColor,
};
const feature3 = {
  backgroundColor: "#F77575",
  color: feature3TextColor,
};

const feature4TextColor = "#7A6B1A";
const feature4Text = {
  color: feature4TextColor,
};
const feature4 = {
  backgroundColor: "#E3D91A",
  color: feature4TextColor,
};

const feature5TextColor = "#09505B";
const feature5Text = {
  color: feature5TextColor,
};
const feature5 = {
  backgroundColor: "#4EB3C0",
  color: feature5TextColor,
};

const feature6TextColor = "#8C2B9D";
const feature6Text = {
  color: feature6TextColor,
};
const feature6 = {
  backgroundColor: "#D587E3",
  color: feature6TextColor,
};

class HomePage extends React.Component {
  render() {
    return (
      <div className="HomePage">
        <Helmet>
          <meta
            property="og:title"
            content="Eco POS - Cashier App for your Android"
          />
          <title>Eco POS - Cashier App for your Android</title>
        </Helmet>
        <TemplateHeader />
        <section className="hero" id="HomePage-hero">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-6">
                  <div className="mt-6 is-hidden-mobile"></div>
                  <h1 className="title is-size-1 is-size-3-mobile">
                    Cashier On Your Hand
                  </h1>
                  <p className="subtitle is-6">
                    A Point of Sale app that provides inventory management,
                    receipt printing, analytic page, and many more!
                  </p>
                  <a href="https://play.google.com/store/apps/details?id=com.tujuhlab.ecopos&amp;utm_source=homepage&amp;utm_campaign=homepage&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      alt="Get it on Google Play"
                      width="200"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </div>
                <div className="column is-hidden-touch"></div>
                <div className="column is-hidden-touch">
                  <img src={phone} alt="Phone" id="phone-img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" id="feature-section">
          <div className="is-hidden-touch pb-6"></div>
          <div className="container">
            <section className="mb-6">
              <h1 className="title is-1 has-text-centered is-size-3-mobile">
                Features
              </h1>
              <p className="subtitle has-text-centered is-size-6-mobile">
                Learn more about the benefit of Eco POS
              </p>
            </section>
            <section className="block columns mb-5">
              <div className="column is-2 is-hidden-touch">
                <div className="feature-circle" style={feature1}>
                  <span className="icon is-large">
                    <i className="fas fa-cash-register fa-4x"></i>
                  </span>
                </div>
              </div>
              <div className="column">
                <h3 className="title is-size-4-mobile" style={feature1Text}>
                  <span className="icon-text">
                    <span
                      className="icon is-hidden-desktop mr-4"
                      style={feature1Text}
                    >
                      <i className="fas fa-cash-register"></i>
                    </span>
                    <span className="feature-title">Quick Checkout</span>
                  </span>
                </h3>
                <p className="subtitle is-6">
                  Create your new transaction in seconds by using the intuitive
                  and simple UI from Eco POS.
                </p>
              </div>
              <div className="column is-2 is-hidden-touch">
                <div className="feature-circle" style={feature3}>
                  <span className="icon is-large">
                    <i className="fas fa-money-check-alt fa-4x"></i>
                  </span>
                </div>
              </div>
              <div className="column">
                <h3 className="title is-size-4-mobile" style={feature3Text}>
                  <span className="icon-text">
                    <span
                      className="icon is-hidden-desktop mr-4"
                      style={feature3Text}
                    >
                      <i className="fas fa-money-check-alt"></i>
                    </span>
                    <span className="feature-title">Attach Your Fees</span>
                  </span>
                </h3>
                <p className="subtitle is-6">
                  Add delivery fee, service fee, or sales tax to your invoice.
                </p>
              </div>
            </section>
            <section className="block columns mb-5">
              <div className="column is-2 is-hidden-touch">
                <div className="feature-circle" style={feature5}>
                  <span className="icon is-large">
                    <i className="fas fa-box-open fa-4x"></i>
                  </span>
                </div>
              </div>
              <div className="column">
                <h3 className="title is-size-4-mobile" style={feature5Text}>
                  <span className="icon-text">
                    <span
                      className="icon is-hidden-desktop mr-4"
                      style={feature5Text}
                    >
                      <i className="fas fa-box-open"></i>
                    </span>
                    <span className="feature-title">Inventory Management</span>
                  </span>
                </h3>

                <p className="subtitle is-6">
                  Categorize your products, use your own unit measurements, and
                  manage your stocks.
                </p>
              </div>

              <div className="column is-2 is-hidden-touch">
                <div className="feature-circle" style={feature2}>
                  <span className="icon is-large">
                    <i className="fas fa-users fa-4x"></i>
                  </span>
                </div>
              </div>
              <div className="column">
                <h3 className="title is-size-4-mobile" style={feature2Text}>
                  <span className="icon-text">
                    <span
                      className="icon is-hidden-desktop mr-4"
                      style={feature2Text}
                    >
                      <i className="fas fa-users"></i>
                    </span>
                    <span className="feature-title">Customer Data</span>
                  </span>
                </h3>

                <p className="subtitle is-6">
                  Save your customers information and utilize Pay Later for loan
                  management.
                </p>
              </div>
            </section>
            <section className="block columns mb-5">
              <div className="column is-2 is-hidden-touch">
                <div className="feature-circle" style={feature6}>
                  <span className="icon is-large">
                    <i className="fas fa-receipt fa-4x"></i>
                  </span>
                </div>
              </div>
              <div className="column">
                <h3 className="title is-size-4-mobile" style={feature6Text}>
                  <span className="icon-text">
                    <span
                      className="icon is-hidden-desktop mr-4"
                      style={feature6Text}
                    >
                      <i className="fas fa-receipt"></i>
                    </span>
                    <span className="feature-title">Create Receipt</span>
                  </span>
                </h3>
                <p className="subtitle is-6">
                  Print receipts with your bluetooth thermal printer or share
                  them directly to your customers, free of watermark!
                </p>
              </div>
              <div className="column is-2 is-hidden-touch">
                <div className="feature-circle" style={feature4}>
                  <span className="icon is-large">
                    <i className="fas fa-chart-bar fa-4x"></i>
                  </span>
                </div>
              </div>
              <div className="column">
                <h3 className="title is-size-4-mobile" style={feature4Text}>
                  <span className="icon-text">
                    <span
                      className="icon is-hidden-desktop mr-4"
                      style={feature4Text}
                    >
                      <i className="fas fa-chart-bar"></i>
                    </span>
                    <span className="feature-title">Track Your Revenue</span>
                  </span>
                </h3>
                <p className="subtitle is-6">
                  Improve your sales by analyzing your busiest hours and your
                  best-selling products.
                </p>
              </div>
            </section>
          </div>
        </section>
        <TemplateFooter />
      </div>
    );
  }
}

export default HomePage;
